<template>
  <div>
    <div class="min-h-screen bg-branding-light_green">
      <div class="bg-branding-gray py-12 md:py-36">
        <img class="mx-auto h-36 w-auto" src="../assets/logo.svg" alt="Markus Wipperfürth" />
      </div>
      <div class="bg-branding-light_green text-center py-12 w-full" v-if="!error.show">
        <h1 class="font-thin uppercase text-4xl sm:text-6xl md:text-8xl">
          Abbuchung suchen
        </h1>
        <input id="token" name="token" v-model="token" type="text" required class="uppercase text-white bg-branding-light_gray border-none placeholder-current text-center min-w-1/2 text-3xl mt-8" placeholder="Abbuchungscode" />
        <label for="token" class="block text-2xl">
          – dieser steht in Klammern am Ende der Abbuchung –
        </label>
        <input id="security" name="token" v-model="security" type="text" required class="uppercase text-white bg-branding-light_gray border-none placeholder-current text-center min-w-1/2 text-3xl mt-8" placeholder="4 ZIFFERN DER IBAN" />
        <label for="security" class="block text-2xl">
          – hier bitte die letzten vier Stellen Ihrer IBAN eingeben –
        </label>
        <button @click="search" class="text-3xl uppercase text-white bg-branding-green text-center min-w-1/2 mt-24 h-12">
          Suchen
        </button>
      </div>
      <div v-else class="bg-branding-light_green text-center py-12 w-full">
        <h1 class="font-thin uppercase text-4xl sm:text-6xl md:text-8xl">
          Abbuchung suchen
        </h1>
        <button @click="error.show = false" class="text-3xl uppercase text-white bg-branding-gray text-center p-4 mt-48">
          KEIN ERGEBNIS
        </button>
        <p class="text-2xl mt-2">
          {{ error.code === 401 ? 'Es wurden falsche Daten eingegeben.' : error.code === 410 ? 'Die Abrechnung ist nicht mehr verfügbar.' : error.code === 429 ? 'Es wurden zu oft falsche Daten eingegeben. Versuchen Sie es später erneut.' : 'Ein Fehler ist aufgetreten.'}}
        </p>
        <p class="text-2xl">
          Überprüfen Sie Ihre Angaben.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SearchForm',
  data() {
    return {
      token: '',
      security: '',
      error: {
        show: false,
        code: 400
      }
    }
  },
  created() {
    var parameters = window.location.pathname.split('/');
    parameters.shift();
    if(parameters.length > 0) {
      this.token = parameters.shift();
      this.security = parameters.shift();
    }
    if(this.security) this.search();
  },
  methods: {
    search() {
      axios.get(`https://app2.hof.services/api/transfer/${this.token}/${this.security}`)
        .then(x => {
          window.history.pushState('page2', 'Markus Wipperfürth | Abbuchung einsehen', "/" + this.token + "/" + this.security);
          if(x.status === 200) {
            return this.$emit('successful', x.data);
          }
          this.error.show = false;
        })
      .catch(x => {
        this.error.show = true
        this.error.code = x.response.status
      });
    }
  }
}
</script>
