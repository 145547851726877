<template>
    <div>
        <h3 class="text-7xl font-thin tracking-widest text-gray-900 text-center">Informationen zur Abbuchung</h3>
        <div class="overflow-hidden sm:rounded-lg container mx-auto">
            <div class="px-4 py-5 sm:px-6">
                <p class="mt-1 text-sm text-gray-700 text-2xl text-center">
                    Hier finden Sie alle Informationen zu Ihrer Abbuchung. Dies stellt keine Rechnung dar.
                </p>
            </div>
            <div class="px-4 py-5 sm:px-6">
                <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500 bg-white uppercase p-2 tracking-wide">
                            Kunde
                        </dt>
                        <dd class="mt-1 text-sm text-gray-500 font-bold bg-white p-2 tracking-wide">
                            {{ data.customer.name }}
                        </dd>
                    </div>
                    <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500 bg-white uppercase p-2 tracking-wide">
                            Kundennummer
                        </dt>
                        <dd class="mt-1 text-sm text-gray-500 font-bold bg-white p-2 tracking-wide">
                            {{ data.customer.nr }}
                        </dd>
                    </div>
                    <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500 bg-white uppercase p-2 tracking-wide">
                            Kontoinhaber
                        </dt>
                        <dd class="mt-1 text-sm text-gray-500 font-bold bg-white p-2 tracking-wide">
                            {{ data.sepa.name }}
                        </dd>
                    </div>
                    <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500 bg-white uppercase p-2 tracking-wide">
                            IBAN
                        </dt>
                        <dd class="mt-1 text-sm text-gray-500 font-bold bg-white p-2 tracking-wide">
                            {{ data.sepa.iban.toUpperCase() }}
                        </dd>
                    </div>
                    <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500 bg-white uppercase p-2 tracking-wide">
                            Referenz
                        </dt>
                        <dd class="mt-1 text-sm text-gray-500 font-bold bg-white p-2 tracking-wide">
                            {{ data.sepa.reference }}
                        </dd>
                    </div>
                    <div class="sm:col-span-1">
                        <dt class="text-sm font-medium text-gray-500 bg-white uppercase p-2 tracking-wide">
                            Abbuchungstext
                        </dt>
                        <dd class="mt-1 text-sm text-gray-500 font-bold bg-white p-2 tracking-wide">
                            {{ data.sepa.text }}
                        </dd>
                    </div>
                    <div class="sm:col-span-2">
                        <dt class="text-sm font-medium text-gray-500 bg-white uppercase p-2 tracking-wide">
                            Positionen
                        </dt>
                        <dd class="mt-1 text-sm text-gray-500 font-bold">
                            <div class="overflow-hidden overflow-x-scroll border-b border-gray-800">
                                <table class="min-w-full divide-y divide-gray-800 overflow-x-scroll">
                                    <thead class="bg-pb">
                                    <tr>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-50 uppercase tracking-wider">
                                            Artikel
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-50 uppercase tracking-wider">
                                            Beschreibung
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-50 uppercase tracking-wider">
                                            Anzahl
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-50 uppercase tracking-wider">
                                            Einzelpreis
                                        </th>
                                        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-50 uppercase tracking-wider">
                                            Summe
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody class="bg-gray-200 divide-y divide-gray-500">
                                    <tr v-for="item in data.values" :key="item" :class="item.type === 'horse' || item.type === 'sum' ? 'bg-white' : ''">
                                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                                            <template v-if="item.name">
                                                {{ item.name }}
                                                <span v-if="item.type !== 'horse'" class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ml-4" :class="item.type === 'stable_price' ? 'bg-indigo-100 text-gray-50' : 'bg-blue-800 text-white'">
                                                {{ item.type === 'stable_price' ? 'Stallmiete' : 'Zubuchoptionen' }}
                                            </span>
                                            </template>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                            <template v-if="item.type === 'stable_price'">
                                                {{ item.desc }}
                                            </template>
                                            <template v-else-if="item.date">
                                                {{ item.desc }}
                                                <br v-if="item.desc !== ''">
                                                Leistungdatum:
                                                {{ new Intl.DateTimeFormat('de-DE').format(new Date(item.date)) }}
                                            </template>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                            <template v-if="typeof item.quantity === 'number'">
                                                {{ new Intl.NumberFormat('de-DE', { maximumFractionDigits: 4 }).format(item.quantity) }}
                                            </template>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                            <template v-if="typeof item.full_value === 'number'">
                                                {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item.full_value) }}
                                            </template>
                                        </td>
                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                            <template v-if="typeof item.value === 'number'">
                                                {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(item.value) }}
                                            </template>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ListItems",
        props: ['data']
    }
</script>

<style scoped>

</style>