<template>
  <div id="app" class="min-h-screen">
        <header class="header">
            <div class="header__left-section">
                <input type="checkbox" id="hamburger" class="menu-control__checkbox">
                <label class="menu-control__label" for="hamburger"><i></i></label>
                <img class="header__logo-img inline" src="@/assets/Logo_MW_weiss.svg">
            </div>
            <div class="header__right-section hidden md:block">
                <a href="tel:+491773261445" class="header__right-section--link">
                    <img class="header__right-section--link-image" src="@/assets/Telefon.svg">
                    +49 177 3261445
                </a>
                <a href="mailto:info@markuswipperfuerth.de" class="header__right-section--link header__right-section--link__second-link">
                    <img class="header__right-section--link-image" src="@/assets/Kontakt.svg">
                    Kontakt
                </a>
            </div>
        </header>
        <main class="">

          <transition enter-active-class="transform ease-out duration-300 transition"
                      enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                      enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                      leave-active-class="transition ease-in duration-100"
                      leave-from-class="h-full"
                      leave-to-class="h-0">
            <SearchForm @successful="show_items" v-if="!data" />
          </transition>
          <transition enter-active-class="transform ease-out duration-300 transition"
                      enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                      enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                      leave-active-class="transition ease-in duration-100"
                      leave-from-class="h-full"
                      leave-to-class="h-0">
            <ListItems :data="data" />
          </transition>

        </main>
    <footer class="footer">
      <div class="footer__logo">
        <img class="footer__logo-img" src="@/assets/LOGO_MW_MIT_CLAIM.svg" alt="Markus Wipperfürth. mehr als nur auf einen Punkt gebracht">
      </div>
      <div class="footer__address">
        Markus Wipperfürth<br>
        HAHNENHOF<br>
        50259 Pulheim<br>
        Tel.: +49 177 3261445
      </div>
      <div class="footer__cta">
        <div class="footer__cta__links">
          <ul>
            <li>
              <a href="https://markuswipperfürth.de/impressum">
                Impressum
              </a>
            </li>
            <li>
              <a href="https://markuswipperfürth.de/datenschutz">
                Datenschutz
              </a>
            </li>
          </ul>
        </div>
        <div class="footer__cta__logos">
          <a title="Markus Wipperfürth WhatsApp" href="https://wa.me/+491773261445">
            <img src="@/assets/WhatsApp_Logo.svg" alt="WhatsApp">
          </a>
          <a title="Lohnunternehmen Markus Wipperfürth Facebook" href="https://www.facebook.com/Lohnunternehmen-Markus-Wipperf%C3%BCrth-486021428226080">
            <img src="@/assets/f_logo_RGB-White_1024.svg" alt="Facebook">
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import SearchForm from "@/components/SearchForm";
import ListItems from "@/components/ListItems";

export default {
  name: 'App',
  components: {
    ListItems,
    SearchForm
  },
  created () {
      document.title = 'Markus Wipperfürth | Abbuchung einsehen'
  },
  data () {
    return {
      data: false
    }
  },
  methods: {
    show_items: function (data) {
      this.data = data;
    }
  }
}
</script>
